

const postEmpleosFinamo = () => {
    const URL = process.env.REACT_APP_URL_API_INTRANET;
    const resp = fetch(`${URL}/empleos/all/activos/1`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.result) {
          if(responseData.status === 403){
            return {"status": 403};
          }
          else if(responseData.status === 90){
            return {"status": 90};
          }
          else{
            return {"status": 500};
          }
        }
        else{
          return responseData.data;
        }
      })
      .catch(err => {
        return {"status":500};
    });
    return resp;
};

const postFiltrarEmpleosFinamo = (nombreSearch) => {

  console.log("Valores: ",nombreSearch );
  const URL = process.env.REACT_APP_URL_API_INTRANET;
  const resp = fetch(`${URL}/empleos/all/${nombreSearch}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (!responseData.result) {
        if(responseData.status === 403){
          return {"status": 403};
        }
        else if(responseData.status === 90){
          return {"status": 90};
        }
        else{
          return {"status": 500};
        }
      }
      else{
        return responseData.data;
      }
    })
    .catch(err => {
      return {"status":500};
  });
  return resp;
};

const postVacanteFinamo = (vacanteId) => {
  const URL = process.env.REACT_APP_URL_API_INTRANET;
  const resp = fetch(`${URL}/empleos/${vacanteId}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (!responseData.result) {
        if(responseData.status === 403){
          return {"status": 403};
        }
        else if(responseData.status === 90){
          return {"status": 90};
        }
        else{
          return {"status": 500};
        }
      }
      else{
        return responseData.data;
      }
    })
    .catch(err => {
      return {"status":500};
  });
  return resp;
};



const EmpleosService = {
  postEmpleosFinamo,
  postVacanteFinamo,
  postFiltrarEmpleosFinamo
}

export default EmpleosService;

import { FloatButton } from "antd";


import Structure from '../componentes/Structure';

const AvisoPrivacidadModal = () => {

  return (
   

   
    <div
      style={{
        
        backgroundColor: '#fff',
        marginTop: 5,
        borderRadius: 15,
        padding: 20

      }}
    >
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        
        <center>
          <div style={{fontSize: 30, fontWeight:900,color:"#00BD70", marginBottom:20}} >AVISO DE PRIVACIDAD</div>
        </center>
        {
          //className="justificado"
        }
        <div class="col-12 pt-5 mb-5">
          <h4>RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES</h4>
          <p className='justificado'>
            Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección 
            de Datos Personales en Posesión de Particulares publicada en el Diario Oficial 
            de la Federación del 10 de Julio de 2010, hacemos de su conocimiento que 
            ADMINISTRADORA DE SOLUCIONES S.A. DE C.V. SOFOM E.N.R., 
            y/o ARRENDADORA FÍNAMO, S.A. DE C.V., (en lo sucesivo “Fínamo”), con domicilio en 
            Alfonso G. Calderón Velarde 2656 int. 11, Col. Desarrollo Urbano Tres Ríos C.P. 80020 
            Culiacán de Rosales, Culiacán, Sinaloa, es responsable de recabar sus datos personales, 
            del uso que se le dé a los mismos y de su protección.
          </p>
          <p className='justificado'>
            Al ingresar y utilizar la página de internet, cuyo nombre de dominio es: www.finamo.mx, 
            registrado por Fínamo, usted (el titular) declara que está aceptando los términos y las 
            condiciones contenidos en este aviso y declara y otorga expresamente su aceptación y 
            consentimiento utilizando para tal efecto medios electrónicos, en términos de lo dispuesto 
            por el artículo 1803 del Código Civil Federal.
          </p>
          <p className='justificado'>
            Si el titular no acepta en forma absoluta y completa los términos y condiciones de este aviso,
            deberá abstenerse de compartir cualquier tipo de información a Fínamo por cualquier medio 
            incluyendo el sitio: www.finamo.mx.
          </p>
        </div>

        <div class="col-12 mb-5" >
          <h4>DATOS QUE SE RECABAN</h4>
          <p >
            Para las finalidades mencionadas en el presente aviso, requerimos
            obtener los siguientes datos personales:
          </p>
          <ul>
            <li>Datos de identificación.</li>
            <li>Datos de contacto.</li>
            <li>Datos laborales.</li>
            <li>Datos Patrimoniales.</li>
            <li>Datos financieros.</li>
            <li>Datos comerciales.</li>
            <li>Datos de terceros.</li>
          </ul>
          <p className="justificado">
            Datos personales de identificación y contacto de terceros cuando usted 
            nos proporcione esta información para que dichos terceros sean contactados 
            en su calidad de referencias para fines de corroboración e investigación. 
            Le informamos que, al proporcionar esta información, usted reconoce haber 
            informado a dichos terceros sobre el uso de sus datos y haber obtenido de 
            forma previa el consentimiento de éstos últimos para que Fínamo pueda 
            contactarles para los fines antes señalados.
          </p>
          <p className="justificado">
            Usted reconoce y acepta que Fínamo, obtendrá directamente los siguientes 
            datos personales, tales como: Nombre completo, edad, género, correo electrónico, 
            teléfono y/o teléfono móvil, domicilio, fecha y lugar de nacimiento, RFC, 
            Número de Seguro Social (NSS), CURP, fotografía, información sobre grados académicos, 
            historial académico y laboral (empleos anteriores), datos relativos a interés en 
            puesto de trabajo, aptitudes y capacidades, pretensiones laborales (incluyendo sueldos).
          </p>
          <p className="justificado">
            Además  de los datos  personales mencionados anteriormente y  para las finalidades 
            informadas  en el aviso de privacidad  Fínamo utiliza los siguientes datos personales 
            considerados como sensibles  que requieren especial atención tales como:  
            estado de salud presente y/o futuro, enfermedades que padece, alergias, datos patrimoniales,
            financieros y medicamentos que toma historial crediticio, copia del acta de matrimonio 
            (de ser necesario), sueldo, percepciones y deducciones, información relacionada con 
            beneficiarios o dependientes económicos, fecha de nacimiento y otros datos de 
            identificación, tales como acta de nacimiento y otros datos de identificación, estos se 
            tratarán como datos sensibles conforme al articulo 9 de la LFPDPPP.
          </p>
          <p className="justificado">
            Usted, en este acto, otorga su consentimiento expreso en términos del artículo 9 
            de la LFPDPPP, y el artículo 11, 12, 13 y demás relativos y aplicables del RLFPDPPP, 
            para que Fínamo, trate sus datos personales contenidos en ésta cláusula, para cumplir 
            con las finalidades que establece el presente Aviso de Privacidad.
          </p>
          <p className="justificado">
            Fínamo manifiesta que podrá obtener los datos personales de EL TITULAR mediante las 
            denominadas fuentes de acceso público, a efecto de validar y actualizar los datos de 
            EL TITULAR, respetando en todo momento la expectativa razonable de privacidad, a que 
            se refiere el artículo 7 de la LFPDPPP.
          </p>
          <p className="justificado">
            Fínamo manifiesta que podrá obtener los datos personales mediante las denominadas 
            fuentes de acceso público, a efecto de validar y actualizar los datos, respetando
            en todo momento la Ley Federal de Protección de Datos Personales en Posesión de Particulares.
            El tratamiento de los datos personales recabados será el que resulte necesario, adecuado 
            y relevante para el cumplimiento de las finalidades que se mencionan a continuación, 
            así como fines distintos que resulten compatibles o análogos a los mismos, sin que para 
            ello se requiera obtener el consentimiento del titular.
          </p>
        </div>

        <div class="col-12 mb-5" >
          <h4>FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES</h4>
          <ul>
            <li>
              Los datos descritos serán utilizados para las siguientes finalidades necesarias: 
              Verificar, confirmar y validar su identidad durante la prestación de los servicios, 
              procesos de identificación y conocimiento de cliente.{' '}
            </li>
            <li>
              Otorgamiento; administración; y cobranza interna y extrajudicial
              de los servicios financieros que usted solicite a Fínamo.{' '}
            </li>
            <li>
              Realizar consultas y seguimiento a sus quejas, reclamaciones,
              aclaraciones o solicitudes relacionadas con los servicios
              financieros.
            </li>
            <li>
              Dar cumplimiento a las obligaciones contractuales derivadas de su
              solicitud de servicios financieros.
            </li>
            <li>
              Prevenir y detectar fraudes o ilícitos en agravio de su persona, o
              bien, de Fínamo.
            </li>
            <li>Procesos de auditoría externa e interna.</li>
            <li>Ceder o transmitir los derechos de crédito de crédito.</li>
            <li>
              Dar cumplimiento a las disposiciones legales aplicables a los
              servicios financieros.{' '}
            </li>
            <li>
              Dar cumplimiento a requerimientos de autoridades que nos regulan.
            </li>
            <li>Promoción de oferta laboral propia o frente a terceros.</li>
            <li>
              Procesos de contratación laboral propia o de prestación de servicios 
              independientes frente a terceros.
            </li>
            <li>
              Entrega de referencias en caso de que otra persona o empresa solicite informes 
              sobre posibles candidatos a un puesto, trabajo, cargo o comisión.
            </li>
          </ul>

          <p className="justificado">
            La información que obtenemos de nuestros clientes contribuye a 
            mejorar continuamente nuestros servicios y atención a clientes, por 
            lo que hacemos de sus datos para las finalidades no necesarias 
            subsecuentes que nos permiten mejorar nuestros servicios, hacerle 
            partícipe de nuestros nuevos productos y brindarle una mejor 
            experiencia de atención al cliente: (i) promoción, telemarketing, 
            o publicidad; (ii) ofrecimiento de productos y servicios financieros 
            de Fínamo u otras empresas pertenecientes al mismo grupo corporativo; 
            (iii) mercadotecnia, estadística y reporteo; (iv) análisis estadísticos; 
            (v) proyecciones financieras; (vi) comportamientos históricos; 
            (vii) análisis socio demográfico; (viii) estrategias de mercado; y 
            (ix) prospección comercial.
          </p>
          <p className="justificado">
            En caso de que no desee que Fínamo dé tratamiento a sus datos personales 
            para las finalidades no necesarias previstas en el párrafo anterior, 
            tendrá un plazo de 5 (cinco) días posteriores a que sus datos fueron recabados 
            para manifestar su negativa dirigiendo una comunicación a la Unidad Especializada 
            de Atención a Clientes de Fínamo o enviando un correo electrónico a la siguiente 
            dirección: unidadespecializada@finamo.mx de lo contrario se entenderá que ha 
            otorgado su consentimiento.
          </p>
          <p className="justificado">
            Una vez concluida su relación con Fínamo, Fínamo mantendrá sus datos
            personales en una base de datos segura y confidencial por un periodo
            de diez años en cumplimiento a las disposiciones que rigen a Fínamo;
            dentro de dicho término se podrá dar continuidad al tratamiento de
            sus datos para los fines no necesarios.
          </p>
        </div>

        <div class="col-12 mb-5" >
          <h4>TRANSFERENCIA DE DATOS PERSONALES</h4>
          <p className="justificado">
            En virtud del presente aviso de privacidad el titular acepta que sus datos 
            pueden ser transferidos en el entendido de que Fínamo comunicará este aviso
            de privacidad y las finalidades a las que el titular sujeto su tratamiento, 
            cuando se trate de terceros distintos del Responsable y que el tercero asumirá 
            las mismas obligaciones que corresponden al responsable que transfirió los 
            datos por lo que el tratamiento de los datos se hará conforme a lo convenido 
            en este aviso de privacidad.
          </p>
          <p className="justificado">
            Las transferencias de datos podrán llevarse a cabo sin el consentimiento 
            del titular, cuando sean necesarias en virtud de un contrato celebrado, 
            cuando se requiera el cumplimiento de una relación jurídica entre el 
            responsable y el titular, tales como: La trasferencia de datos de 
            identificación y de contacto o datos laborales con terceros prestadores 
            de servicios de mensajería, calificación de riesgos, elaboración de contratos, 
            custodia de documentos, auditoria externa a través de consultores y contratistas, 
            asistencia legal de peritos, sociedades de información crediticia, terceros
            para verificar, confirmar y validar su identidad durante la prestación de los 
            servicios abogados en gestión de cobranza extrajudicial y judicial.
          </p>
          <p className="justificado">
            Transferencia de datos de identificación y de contacto o de datos laborales a 
            despachos de cobranza que fungen como representantes del tratamiento de sus 
            datos personales por medio de la venta de cartera de clientes.
          </p>
          <p className="justificado">
            Fínamo solo comparte su información con terceros que nos prestan servicios 
            relacionados con los que proporcionamos, como puede ser auditores, 
            instituciones de fondeo, autoridades gubernamentales y proveedores de sistemas. 
            Sin embargo, Fínamo se compromete a que el manejo de sus datos personales 
            se realiza con privacidad y seguridad técnica, con el fin de proteger su información.
          </p>
          <p className="justificado">
            Fínamo podrá, en todo momento, comunicar sus datos personales para atender 
            requerimientos de las autoridades reguladoras competentes. En el mismo sentido, 
            Fínamo podrá, en cualquier momento, transferir sus datos personales para 
            atender auditorías que terceros puedan realizarse para garantizar su funcionamiento 
            y cumplimiento sobre la normatividad que le aplica.
          </p>
          <p className="justificado">
            Fínamo podrá transferir sus datos a sociedades controladoras, subsidiarias o 
            afiliadas bajo el control común del Fínamo, o a una sociedad matriz o a 
            cualquier sociedad del mismo grupo empresarial que opere bajo los mismos 
            procesos y políticas internas.
          </p>
        </div>
        <div class="col-12 mb-5" c>
          <h4>
            DERECHOS ARCO (ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN)
          </h4>
          <p className="justificado">
            Todos los datos personales son tratados de conformidad con la legislación 
            aplicable y vigente, por ello usted tiene en todo momento el derecho a 
            acceder a los datos personales que posee Fínamo y a los detalles del 
            tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos 
            o incompletos; cancelarlos cuando resulten ser excesivos o innecesarios para 
            las finalidades que justificaron su obtención; y oponerse a su tratamiento.
          </p>
        </div>
        <div class="col-12 mb-5">
          <h4>
            MEDIOS PARA EJERCER DERECHOS ARCO (ACCESO, RECTIFICACIÓN,
            CANCELACIÓN Y OPOSICIÓN)
          </h4>
          <p className="justificado">
            Para dar inicio a una Solicitud ARCO, usted deberá presentar una solicitud por 
            escrito dirigida a nuestra Unidad Especializada de Atención a Clientes al 
            correo unidadespecializada@finamo.mx o notificación por escrito a Alfonso 
            G. Calderón 2656 Col. Desarrollo Urbano Tres Ríos C.P. 80020 Culiacán de Rosales, 
            Culiacán, Sinaloa, atención a la Unidad Especializada de Atención a Clientes de 
            Lunes a Viernes de 9:00 a 14:00 horas y de 16:00 a 19:00 horas y Sábado de 
            09:00 a 14:00 horas, que contenga la siguiente información:
          </p>
          <p className="justificado">
            Nombre completo del titular, Domicilio del titular o dirección de correo electrónico 
            para comunicar respuesta a solicitud Documentos que acrediten identidad o autorización 
            para representarlo en la solicitud. Descripción de datos personales sobre los que se 
            pretende ejercer algún derecho ARCO Fecha de atención y nombre de la sucursal en el 
            cual recibió los servicios Cualquier otro elemento que permita la localización de los 
            datos personales y atención a la solicitud La respuesta se emitirá en un plazo máximo 
            de veinte días, contados a partir de la fecha en que recibió la solicitud que corresponda, 
            misma que de resultar procedente, se hará efectiva en un plazo de quince días posteriores 
            a la fecha en que se comunica la respuesta al titular, la cual podrá realizarse a través 
            de cualquiera de los medios siguientes: correo electrónico, telefónicamente, o 
            personalmente en el domicilio de la empresa si se acuerda con el cliente.
          </p>
        </div>
        <div class="col-12 mb-5" >
          <h4>MEDIOS Y PROCEDIMIENTO PARA REVOCAR CONSENTIMIENTO</h4>
          <p className="justificado">
            En cualquier momento usted pueden solicitar la revocación del consentimiento 
            otorgado a Fínamo para tratar sus datos personales enviando una solicitud 
            por escrito dirigida a nuestro Unidad Especializada de Atención a Clientes 
            a unidadespecializada@finamo.mx o enviando una notificación por escrito a 
            Alfonso G. Calderón 2656 Col. Desarrollo Urbano Tres Ríos C.P. 80020 
            Culiacán de Rosales, Culiacán, Sinaloa, atención a Unidad Especializada 
            de Atención a Clientes, de Lunes a Viernes de 8:00 a 14:00 horas y de 16:00 
            a 19:00 horas y Sábado de 09:00 a 14:00 horas en la que se detalle claramente 
            los datos respecto de los que revoca su consentimiento.
          </p>
        </div>
        <div class="col-12 mb-5" >
          <h4>
            OPCIONES Y MEDIOS PARA LIMITAR EL USO Y/O DIVULGACIÓN DE LOS DATOS
            PERSONALES
          </h4>
          <p className="justificado">
            Fínamo se compromete a realizar su mejor esfuerzo para proteger la seguridad 
            de los datos personales que le está entregando, mediante la celebración de 
            actos jurídicos y el efectivo uso de tecnologías que controlen el acceso, 
            uso o divulgación sin autorización de la información personal. Para tal efecto, 
            se almacena la información personal en bases de datos con acceso limitado que 
            se encuentran en instalaciones controladas con mecanismos de seguridad. Fínamo 
            se compromete a que la información proporcionada, sea considerada con carácter 
            confidencial, y utilizada bajo plena privacidad.
          </p>
          <p className="justificado">
            En este tenor, Fínamo en algunas ocasiones podrá fungir como el Encargado o Tercero, 
            y por lo tanto se obliga a tomar las medidas necesarias para garantizar que se 
            cumplan con las finalidades establecidas en el presente Aviso de Privacidad.
          </p>
          <p className="justificado">
            Como titular de los datos personales, usted tiene la posibilidad de limitar su 
            uso o divulgación enviando una solicitud al siguiente correo electrónico: 
            unidadespecializada@finamo.mx o una solicitud por escrito presentada en 
            Alfonso G. Calderón 2656 Col. Desarrollo Urbano Tres Ríos C.P. 80020 Culiacán 
            de Rosales, Culiacán, Sinaloa, atención a Unidad Especializada de Atención a 
            Clientes, de Lunes a Viernes de 8:00 a 14:00 horas y de 16:00 a 19:00 horas y 
            Sábado de 09:00 a 14:00 horas, señalando la limitación deseada al uso de sus 
            datos personales.
          </p>
        </div>
        <div class="col-12 mb-5" >
          <h4>
            INFORMACIÓN OBTENIDA POR MEDIOS REMOTOS O LOCALES DE COMUNICACIÓN
            ELECTRÓNICA U ÓPTICA
          </h4>
          <p className="justificado">
            Fínamo recaba y almacena información a través de ingreso a su sitio Web, 
            información sobre la dirección IP/nombre de dominio del visitante, sitios 
            Web que hagan referencia a su sitio, comportamiento y el tiempo de estadía 
            en el sitio Web, las páginas navegadas, herramientas utilizadas, código postal, 
            tipo de navegador y sistema operativo entre otros.
          </p>
          <p className="justificado">
            Dicha información se obtiene y almacena con el fin de medir la
            actividad del sitio e identificar tendencias sobre navegación que no
            son atribuibles a un individuo en específico.
          </p >
          <p className="justificado">
            La información anteriormente señalada se recopila a través de las
            “cookies”, así como otros medios y mecanismos tecnológicos, tales
            como las etiquetas de pixel, los web bugs, enlaces en correos
            electrónicos, web beacons (etiquetas de Internet, etiquetas de pixel
            y clear GIFs) entre otros.
          </p>
          <p className="justificado">
            La mayoría de los exploradores le permitirán eliminar el uso de
            cookies, bloquear la aceptación de las cookies o recibir una
            advertencia antes de almacenar cookies. Le sugerimos consultar las
            instrucciones de su explorador para utilizar las “cookies”.
          </p>
        </div>
        <div id="cookies"></div>
        <div class="col-12 mb-5">
          <h4>USO DE COOKIES</h4>
          <p className="justificado">
            Su navegador aceptará las cookies y permitirá la recolección
            automática de información a menos que usted cambie la configuración
            predeterminada del navegador. La mayoría de los navegadores web
            permiten que usted pueda gestionar sus preferencias de cookies.
            Puede ajustar su navegador para que rechace o elimine cookies. Los
            siguientes links muestran como ajustar la configuración del
            navegador de los navegadores que son utilizados con más frecuencia:
          </p>
          <ul>
            <li>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=es"
                target="_blank"
              >
                Chrome
              </a>
            </li>
            <li>
              <a
                href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es"
                target="_blank"
              >
                Firefox
              </a>
            </li>
            <li>
              <a
                href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                target="_blank"
              >
                Internet Explorer
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/es-es/HT201265#:~:text=1%20Para%20borrar%20el%20historial%20y%20las%20cookies%2C,en%20el%20historial%2C%20activa%20la%20funci%C3%B3n%20Navegaci%C3%B3n%20privada."
                target="_blank"
              >
                Safari
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12 mb-5">
          <h4>MODIFICACIONES AL AVISO DE PRIVACIDAD</h4>
          <p className="justificado">
            El presente Aviso de Privacidad podrá ser modificado en cualquier 
            momento para cumplir con actualizaciones legislativas, 
            jurisprudenciales, políticas internas, nuevos requisitos para la 
            prestación de servicios de Fínamo o cualquier otra causa a entera 
            discreción de la empresa. En tal caso, las modificaciones estarán 
            disponibles en nuestra página de Internet http://www.finamo.mx y se 
            hará de su conocimiento por correo electrónico, con un mensaje o 
            nota visible en nuestro portal de internet, vía telefónica, por 
            anuncios, por folletos, personalmente o cualquier otro medio de 
            comunicación verbal, impreso o electrónico que Fínamo determine 
            para tal efecto.
          </p>
          <p className="justificado">
            En caso de que no obtengamos su oposición expresa para que sus 
            datos personales sean transferidos en la forma y términos antes 
            descrita, entenderemos que ha otorgado su consentimiento en forma 
            tácita para ello.{' '}
          </p>
          <p className="justificado">
            Si usted considera que su derecho a la protección de datos personales 
            ha sido lesionado por alguna conducta u omisión por parte de Fínamo, 
            o presume alguna violación a las disposiciones previstas en la Ley Federal 
            de Protección de Datos Personales en Posesión de los Particulares, su 
            Reglamente, y demás ordenamientos aplicaciones, podrá interponer su 
            inconformidad o denuncia ante el Instituto Nacional de Transparencia, 
            Acceso a la Información y Protección de Datos Personales (INAI). 
            Para mayor información, consulte la página de Internet del INAI.
          </p>
        </div>
      </div>
    </div>

  );
};

export default AvisoPrivacidadModal;


const postSaveDatosPersonales = (primerNombre, segundoNombre, primerApellido, segundoApellido, fechaNacimiento, nacionalidad, genero, domicilio, email, telefono, linkedln, empleoId, documento) => {
    const URL = process.env.REACT_APP_URL_API_INTRANET;

    const formData = new FormData(); // Crea un objeto FormData
    formData.append('documento', documento); // Adjunta el archivo al objeto FormData

    const resp = fetch(`${URL}/prospectos/save/datos-personales?primerNombre=${primerNombre}&segundoNombre=${segundoNombre}&
                        primerApellido=${primerApellido}&segundoApellido=${segundoApellido}&fechaNacimiento=${fechaNacimiento}&nacionalidad=${nacionalidad}&
                        genero=${genero}&domicilio=${domicilio}&email=${email}&telefono=${telefono}&linkedln=${linkedln}&empleoId=${empleoId}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.result) {
          if(responseData.status==403){
            return {"status": 403};
          }
          else if(responseData.status== 90){
            return {"status": 90};
          }
          else{
            return {"status": 500};
          }
        }
        else{
          return responseData.data;
        }
      })
      .catch(err => {
        return {"status":500};
    });
    return resp;
};

const postGuardaDatosPersonales = (primerNombre, segundoNombre, primerApellido, segundoApellido, email, telefono, telefonoAdic, linkedln, empleoId, documento) => {
  const URL = process.env.REACT_APP_URL_API_INTRANET;

  const formData = new FormData(); // Crea un objeto FormData
  formData.append('documento', documento); // Adjunta el archivo al objeto FormData

  const resp = fetch(`${URL}/prospectos/save/datos-personales?primerNombre=${encodeURIComponent(primerNombre)}&segundoNombre=${encodeURIComponent(segundoNombre)}&primerApellido=${encodeURIComponent(primerApellido)}&segundoApellido=${encodeURIComponent(segundoApellido)}&email=${email}&telefono=${telefono}&telefonoAdic=${telefonoAdic}&linkedln=${linkedln}&empleoId=${empleoId}`, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"status":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { status: 500 };
    });
  return resp;
};

const postObtenerProspecto = (prospectoId) => {
  const URL = process.env.REACT_APP_URL_API_INTRANET;
  const resp = fetch(`${URL}/prospectos/${prospectoId}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"status":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { status: 500 };
    });
  return resp;
};

const postSexos = () => {
  const URL = process.env.REACT_APP_URL_API_INTRANET;
  const resp = fetch(`${URL}/prospectos/all/sexos`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (!responseData.result) {
        if(responseData.status==403){
          return {"status": 403};
        }
        else if(responseData.status== 90){
          return {"status": 90};
        }
        else{
          return {"status": 500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"status":500};
  });
  return resp;
};


const ProspectoService = {
    postSaveDatosPersonales,
    postGuardaDatosPersonales,
    postObtenerProspecto,
    postSexos
  
  }
  
  export default ProspectoService;
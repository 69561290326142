import { Button, Card,  FloatButton, Pagination } from "antd";
import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import EmpleosService from "../services/empleos.service";
import { ComponentesContext } from '../contextos/componentesContext'
import Search from "./Search";

const itemsPerPage = 10;

const Vacantes = () => {
    const navigate = useNavigate();
    const paginationRef = useRef(null);

    const [dataSource, setDataSource] = useState([]);
    const { CloseSession, ModalMessage  } = useContext(ComponentesContext);
    const [nombreSearch, setNombreSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentSection = dataSource.slice(startIndex, endIndex);
    const [showCard, setShowCard] = useState(false);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(()=>{
        getEmpleos();
        const timeout = setTimeout(() => {
            setShowCard(true);
        }, 500); // Puedes ajustar el tiempo de retraso según tus necesidades

        // Limpia el temporizador cuando el componente se desmonta o actualiza
        return () => clearTimeout(timeout);
    },[]);

    useEffect(() => {
        paginationRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [currentPage]); 

    const getEmpleos = async () => {
        let respuesta = await EmpleosService.postEmpleosFinamo();
    
        if (respuesta.status === 403) {
          CloseSession();
          return false;
        }
    
        if (respuesta.status === 500) {
          
          return false;
        }
        setDataSource(respuesta)
    
    };

    const getFiltrarEmpleos = async () => {
        let respuesta = "";
        if (nombreSearch === "") {
            respuesta = await EmpleosService.postEmpleosFinamo();
        } else {
            respuesta = await EmpleosService.postFiltrarEmpleosFinamo(nombreSearch);
        }
    
        if (respuesta.status === 403) {
          CloseSession();
          return false;
        }
    
        if (respuesta.status === 500) {
          
          return false;
        }
        setCurrentPage(1);
        setDataSource(respuesta)
    
    };

    const handleAvanzar = (vacante) => {
        navigate("/vacante-id", {
            state: { record: { dataVacante: vacante } },
        });
    }

    const obtenerDias = (dias) => {
        let result = 'Hoy';

        if (dias > 1) {
            result = `Hace ${dias} Dias`;
        } else if (dias === 1) {
            result = `Hace ${dias} Dia`;
        }
        return result;

    }
      
    return (
       
        <div ref={paginationRef} >
            <br />
            <Search 
                nombreSearch={nombreSearch}
                setNombreSearch={setNombreSearch}
                getFiltrarEmpleos={getFiltrarEmpleos}
            />
            
            <div  style={{ display: 'flex' }}>       
                <div style={{ flex: 5}}>
                
                    <div id="vacantes" className="vacantes">
                        
                        <br />
                        {
                            (currentSection.length > 0) 
                            ?
                            currentSection.map((vacante) => (                                                                 
                                <div key={vacante.id} id="vacantes"  className={`fadeInEffect ${showCard ? 'show' : ''}`} >
                                    <Card 
                                        title={<strong>{vacante?.nombreVacante}</strong>}
                                        bodyStyle={{
                                            cursor: 'pointer', 
                                            marginTop: -20
                                        }} 
                                        headStyle={{
                                            backgroundColor: '#04142b', 
                                            color: '#ffffff',  
                                            cursor: 'pointer'
                                        }}   // backgroundColor: '#5c697c', color: '#ffffff',
                                        extra={
                                            <Button
                                              type="primary"
                                              style={{ height: 37, backgroundColor: '#4D49BE'}}
                                              onClick={() => {
                                                handleAvanzar(vacante);
                                                
                                              }}
                                            >
                                              Ver mas
                                            </Button>
                                          }
                                        onClick={() => handleAvanzar(vacante)}
                                    >
                                       <div className="detallles">
                                           
                                                <div className="part1">
                                                    
                                                        <p > <strong>Jornada: </strong> <br /> {vacante?.jornada?.label} </p>
                                                        <p > <strong>Experiencia: </strong> <br/> {vacante?.experiencia} </p>
                                                        <p > <strong>Esquema: </strong> <br/> {vacante?.esquema?.label} </p>
                                                
                                                </div>
                                                <div className="part2">
                                                        <p > <strong>Horario: </strong> <br /> {vacante?.horario?.label}</p>
                                                        <p > <strong>Lugar de trabajo: </strong> <br /> {vacante?.ubicacion} </p>
                                                        <p > <strong>Escolaridad: </strong> <br /> {vacante?.escolaridad} </p>
                                                        
                                                    
                                                    
                                                </div>
                                                <div className="part3">
                                                        <p> <strong>Descripción del puesto: <br /> </strong> {vacante.descripcion.slice(0, 150)}{(vacante?.descripcion.length > 150) ? "..." : ""} </p>

                                                  
                                                </div>
                                        </div>
                                        <br/>
                                        <div style={{textAlign: 'right'}}>
                                            <p style={{fontSize: '15px', marginTop: -30}} className="fechaPublicacion">Publicado <strong> {obtenerDias(vacante?.dias)} </strong> </p>
                                    
                                        </div>
                                       
                                    </Card>
                                </div>
                            ))
                            :
                            <div style={{textAlign: "center"}}>
                                <h3>NO EXISTEN VACANTES ACTUALMENTE</h3>
                            </div>
                        
                        
                        } 

                    </div>
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                       
                        <Pagination
                            pageSize={10}
                            total={dataSource.length}
                            onChange={handlePageChange}
                            current={currentPage}
                        />
                    </div>
                    <br />
                </div>
                {/* <Nosotros /> */}
                            
                <FloatButton.BackTop />
                    
            </div>
            {/* <Detalle open={open} onClose={onClose} id={id} nombre={nombre}/> */}
        </div>

      

    )
}

export default Vacantes;

import { FloatButton } from "antd";


import Structure from '../componentes/Structure';
import AvisoPrivacidadModal from "../componentes/AvisoPrivacidad";

const AvisoPrivacidad = () => {

  return (
    <Structure>

   
      <AvisoPrivacidadModal />
      <FloatButton.BackTop />
    </Structure>
  );
};

export default AvisoPrivacidad;

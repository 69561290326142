import React from "react";

import { FloatButton, Layout } from "antd";
import HeaderCustom from "../componentes/HeaderCustom";
import FooterCustom from "./FooterCustom"
import { Content } from "antd/es/layout/layout";

export default function Structure({ children }) {

   return (
    
      <Layout
         style={{
            minHeight: "100vh",
         }}
      >
         <Layout>
            {/* <HeaderCustom /> */}
      
            <Layout>
               <Content>
                  {children}
               </Content>
            </Layout>

            {/* <FooterCustom/> */}
         </Layout>
         
      </Layout>
   );
}
import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ComponentesContextProvider } from "./contextos/componentesContext";

import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ComponentesContextProvider>
      <App />
    </ComponentesContextProvider>
  </BrowserRouter>,
  //document.getElementById("root")
);

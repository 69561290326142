import React from "react";

import "./Welcome.css";
import Vacantes from "./Vacantes";

const Welcome = () => {
 


  return (
      <Vacantes />
  );
};
export default Welcome;

import React from "react";
import Welcome from "./pages/Welcome";

import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import DatosPersonales from "./registrar/DatosPersonales";
import VacanteId from "./pages/VacanteId";
import AvisoPrivacidad from "./pages/Privacidad";


const App = () => {

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return(
  
    <div className="App">
      <Routes>
  
        <Route path="/" element={<Welcome/>} />
        <Route path="/datos-personales" element={<DatosPersonales/>} />
        <Route path="/vacante-id" element={<VacanteId/>}/>
        <Route path="/aviso" element={<AvisoPrivacidad />}/>
        
      </Routes>

    </div>
  
    

)};
export default App;
import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Input, Button, Card, Form, Upload, Modal, Checkbox, Typography } from 'antd';
import { InboxOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ComponentesContext } from "../contextos/componentesContext"
import InputCustom from '../componentes/InputCustom';
import ProspectoService from '../services/prospecto.service';
import returnValidUploadFileClass from "../clases/returnValidUploadFile.class"

const FormValidateMessages = {
   required: '${label} es requerido!',
   types: {
      email: '${label} no es valido!',
      number: '${label} no es un numero valido!',
   },
   number: {
      min: '${label} Debe de tener almenos ${min} caracteres!',
   },
   string: {
      min: '${label} Debe de tener almenos ${min} caracteres!',
   },
};

function DatosPersonales() {
   const navigate = useNavigate();
   const { InputTypes, ModalMessage, ShowLoading, ModalSuccess } = useContext(ComponentesContext);

   const [form] = Form.useForm();
   const location = useLocation();

   const [isModalOpen, setisModalOpen] = useState(true);
   const [isModalOpenPriv, setisModalOpenPriv] = useState(false);
   const [isChecked, setChecked] = useState(false);

   const [formPrimerNombre, setformPrimerNombre] = useState("");
   const [formSegundoNombre, setformSegundoNombre] = useState("");
   const [formPrimerApellido, setformPrimerApellido] = useState("");
   const [formSegundoApellido, setformSegundoApellido] = useState("");
   const [formEmail, setformEmail] = useState("");
   const [formTelefono, setformTelefono] = useState("");
   const [formTelefonoAdic, setformTelefonoAdic] = useState("");
   const [formLinkedln, setformLinkedln] = useState("");
   const [fileList, setFileList] = useState([]);
   const [TextoModal, setTextoModal] = useState(null);
   const dataVacante = location.state?.record?.dataVacante ?? [];

   const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight - 190
   });

   const props = {
      onRemove: (file) => {
         const index = fileList.indexOf(file);
         const newFileList = fileList.slice();
         newFileList.splice(index, 1);
         setFileList(newFileList);
      },

      fileList,
   };

   const handleCheckChange = (val) => {
      setChecked(val);
   };

   const handleModalClose = () => {
      setisModalOpenPriv(false);
   };

   const handleAvisoPrivacidad = (url) => {
      window.open(url, '_blank');
   }

   const handleBeforeUpload = async (file) => {
      console.log("File: ", file);
      let flagArchivoValido = await returnValidUploadFileClass.returnPdfValidUploadFile(file.type);

      if (flagArchivoValido == false) {
         ModalMessage("MENSAJE", "El tipo de archivo que se desea adjuntar no es valido.");
         return false;
      }

      if (fileList.length === 0) {
         setFileList([...fileList, file]);
         console.log("Files List: ", fileList);
         return false;
      }

      return true;
   }

   const handleNext = async (option) => {

      if (formPrimerNombre === '') {
         ModalMessage('MENSAJE', 'El Primer Nombre no puede estar en blanco.');
         return false;
      }

      if (formPrimerApellido === '') {
         ModalMessage('MENSAJE', 'El Primer Apellido no puede estar en blanco.');
         return false;
      }

      if (formEmail === '') {
         ModalMessage('MENSAJE', 'El Correo no puede estar en blanco.');
         return false;
      }

      if (formTelefono === '') {
         ModalMessage('MENSAJE', 'El Telefono no puede estar en blanco.');
         return false;
      }

      ShowLoading(true)

      const respuesta = await ProspectoService.postGuardaDatosPersonales(formPrimerNombre, formSegundoNombre, formPrimerApellido, formSegundoApellido, formEmail, formTelefono, formTelefonoAdic, formLinkedln, dataVacante?.key, fileList[0]);

      if (!respuesta.result) {
         ShowLoading(false);
         ModalMessage("ERROR", "Se produjo un error al guardar, favor de volver intentarlo.");
         return false;
      }

      ShowLoading(false)
      const mensaje = (
         <div>
            Nos emociona informarte que tu postulación para la posición de <strong>{dataVacante?.nombreVacante}</strong> ha sido exitosa. Agradecemos tu interés en formar parte de nuestro equipo. Pronto nos pondremos en contacto contigo para continuar con el proceso de selección.
         </div>
      );
      ModalSuccess("POSTULACION EXITOSA", mensaje);
      navigate(option);
   };

   const handleBack = () => {
      navigate("/");
   }

   const convertirAMayusculas = (event) => {
      event.target.value = event.target.value.toUpperCase();
   };

   const handleAceptarClick = () => {
      setisModalOpen(false);
   };

   const handleCancelarClick = () => {
      navigate("/vacante-id", {
         state: { record: { dataVacante: dataVacante } },
      });
   };

   return (
      <div>

         <Card
            title={<strong>{dataVacante?.nombreVacante}</strong>}
            style={{ marginBottom: 10, marginLeft: 10, marginRight: 10, marginTop: 10 }}
            headStyle={{ backgroundColor: '#04142b', color: '#ffffff', }}
            extra={<p><a style={{ color: 'red' }}> * </a> Obligatorios</p>}
         >
            <div>
               <h4 style={{ marginLeft: 20, textAlign: 'center' }} >Por favor llene los campos solicitados y haga click en Guardar.</h4>

            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
               <Form
                  form={form}
                  layout="vertical"
                  style={{ width: "90%" }}
                  validateMessages={FormValidateMessages}
               >
                  <Row gutter={5}>
                     <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                           name={["DatosPersonales", "primerNombre"]}
                           label="Primer nombre"
                           rules={[{ required: true, message: 'Por favor ingresa el primer nombre' }]}
                        >
                           <InputCustom
                              inputtype={InputTypes.Text}
                              value={formPrimerNombre}
                              onChange={setformPrimerNombre}
                              onInput={convertirAMayusculas}
                              key={1}
                           />
                        </Form.Item>
                     </Col>
                     <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Form.Item
                           name={["DatosPersonales", "segundoNombre"]}
                           label="Segundo nombre"
                        >
                           <InputCustom
                              inputtype={InputTypes.Text}
                              value={formSegundoNombre}
                              onChange={setformSegundoNombre}
                              onInput={convertirAMayusculas}
                              key={2}
                           />
                        </Form.Item>
                     </Col>

                  </Row>
                  <Row gutter={5} >
                     <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Form.Item
                           name={["DatosPersonales", "primerApellido"]}
                           label="Primer apellido"
                           rules={[{ required: true, message: 'Por favor ingresa el primer apellido' }]}
                        >
                           <InputCustom
                              inputtype={InputTypes.Text}
                              value={formPrimerApellido}
                              onChange={setformPrimerApellido}
                              onInput={convertirAMayusculas}
                              key={3}
                           />
                        </Form.Item>
                     </Col>
                     <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                           name={["DatosPersonales", "segundoApellido"]}
                           label="Segundo apellido"
                        >
                           <InputCustom
                              inputtype={InputTypes.Text}
                              value={formSegundoApellido}
                              onChange={setformSegundoApellido}
                              onInput={convertirAMayusculas}
                              key={4}
                           />
                        </Form.Item>
                     </Col>
                  </Row>

                  <Row gutter={5} >
                     <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Form.Item
                           name={["DatosPersonales", "email"]}
                           label="Correo electrónico"
                           rules={[
                              {
                                 required: true,
                                 message: 'Ingresa el correo electrónico',
                              },
                              {
                                 type: 'email',
                                 message: 'Ingresa un correo electrónico válido',
                              },
                           ]}
                        >
                           <Input
                              value={formEmail}
                              onInput={convertirAMayusculas}
                              onChange={(e) => setformEmail(e.target.value)}
                           />
                        </Form.Item>
                     </Col>
                     <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Form.Item
                           name={["DatosPersonales", "telefono"]}
                           label="Celular"
                           rules={[{ required: true, message: 'Por favor ingresa un telefono' }]}
                        >
                           <InputCustom
                              inputtype={InputTypes.Integer}
                              value={formTelefono}
                              onChange={setformTelefono}
                              onInput={convertirAMayusculas}
                              key={2}
                              maxLength={10}
                           />
                        </Form.Item>
                     </Col>

                  </Row>
                  <Row gutter={5} >
                     {/*<Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <Form.Item
                                name={["DatosPersonales", "lugarResidencia"]} 
                                label="Lugar de Residencia"
                                rules={[{ required: true, message: 'Por favor ingrese el lugar de residencia' }]}
                                >
                                    <Input 
                                        value={formResidencia}
                                        onChange={(e) => setformResidencia(e.target.value)}
                                        onInput={convertirAMayusculas}
                                    />
                                </Form.Item>
                                </Col>*/}
                     <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Form.Item
                           name={["DatosPersonales", "celularOtro"]}
                           label="Otro número de contacto"
                        >
                           <InputCustom
                              inputtype={InputTypes.Integer}
                              value={formTelefonoAdic}
                              onChange={setformTelefonoAdic}
                              key={2}
                              maxLength={10}
                           />
                        </Form.Item>
                     </Col>

                     <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Form.Item
                           name={["DatosPersonales", "LinkedIn"]}
                           label="LinkedIn"
                        >
                           <Input
                              value={formLinkedln}
                              placeholder='https://mx.linkedin.com/'
                              onChange={(e) => setformLinkedln(e.target.value)}
                              onInput={convertirAMayusculas}
                           />
                        </Form.Item>
                     </Col>

                  </Row>

                  <Row gutter={5} >

                     <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <Form.Item
                           name={["DatosPersonales", "curriculum"]}
                           label="Curriculum"
                           rules={[{ required: true, message: 'Agregar un curriculum' }]}
                        >
                           <Upload.Dragger
                              id="files"
                              name="files"
                              fileList={fileList}
                              beforeUpload={handleBeforeUpload}
                              {...props}
                           >
                              <p className="ant-upload-drag-icon">
                                 <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">Dar click o arrastrar el archivo en esta área</p>
                              <p className="ant-upload-hint">Solo archivos PDF, WORD son permitidos</p>
                           </Upload.Dragger >
                        </Form.Item>
                     </Col>
                  </Row>


               </Form>
            </div>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
               <Button
                  className="buttons-back-forward"
                  onClick={() => handleBack()}
                  type='default'
                  style={{ height: 45, marginRight: 50, backgroundColor: 'red', borderColor: 'red', color: 'white' }}
               >
                  Cancelar
               </Button>
               <Button
                  className="buttons-back-forward"
                  disabled={fileList.length === 0}
                  type="primary"
                  style={{ height: 45, width: 110, backgroundColor: '#00BD70', borderColor: '#00BD70', color: 'white' }}
                  onClick={() => handleNext('/')}
               >
                  Guardar
               </Button>
            </Col>

         </Card>

         <Modal
            centered
            open={isModalOpen}
            style={{}}
            footer={[
               <Button key="cancelar" type="primary" onClick={handleCancelarClick} icon="" >
                  Cancelar
               </Button>,
               <Button key="aceptar" type="primary" onClick={handleAceptarClick} icon="" disabled={!isChecked} >
                  Aceptar
               </Button>

            ]}
            closeIcon={true}
         >
            <p className='justificado'>
               <strong>Aviso:</strong> No te dejes sorprender, Fínamo no exige ningún tipo de pago
               por participar en sus procesos de reclutamiento, evaluación ni contratación de personal.
               Reporta cualquier abuso al correo electrónico <strong>luis.castaneda@fincamex.com.mx</strong> ó al teléfono <strong>667 758 55 88 ext. 1048</strong>
            </p>
            <p className='justificado'>
               Confirmo que las declaraciones hechas por mí, son verdaderas. Queda entendido expresamente,
               que el hecho de que Fínamo reciba la información arriba plasmada, no Implica un
               ofrecimiento de trabajo o una aceptación contractual, sino únicamente el recibo de los datos
               necesarios para un estudio de las posibilidades de su ingreso.
            </p>
            <br />
            <Checkbox onChange={(e) => (handleCheckChange(e.target.checked))} >Al continuar, aceptas nuestro <a onClick={() => handleAvisoPrivacidad("https://www.finamo.mx/aviso_priv_bolsa_trabajo")} stile> aviso de privacidad. </a></Checkbox>



         </Modal>
         <Modal
            title={
               <div>
                  <Typography.Title
                     level={3}
                     style={{ margin: 0, color: '#081b3e', marginBottom: 10 }}
                  >
                     <InfoCircleOutlined style={{ color: '#4c4cbc' }} /> &nbsp;{' '}
                     <label style={{ color: '#4c4cbc' }}>
                        Información.
                     </label>
                  </Typography.Title>

               </div>
            }
            open={isModalOpenPriv}
            onCancel={handleModalClose}
            footer={[<div></div>]}
            width={windowSize.width - 200}
            className="modal-contacto"
         >
            {TextoModal}
         </Modal>


      </div>
   );
}

export default DatosPersonales;

import { useContext } from "react"
import { SearchOutlined } from '@ant-design/icons';
import InputCustom from "../componentes/InputCustom";
import { ComponentesContext } from "../contextos/componentesContext";
import PropTypes from 'prop-types';
import { Button, Form, Col, Row } from "antd";

const Search = ({nombreSearch, setNombreSearch, getFiltrarEmpleos}) => {
    const { InputTypes } = useContext(ComponentesContext);

    return (

        <Form
            layout="vertical"
            style={{ width: "90%" }}
        >
            <Row gutter={5}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item>
                        <h3 style={{textAlign: 'center'}}>Conoce nuestra oferta de vacantes disponibles:</h3>
                    </Form.Item>
                </Col>
                <Col xs={18} sm={18} md={9} lg={9} xl={9} >
                    <Form.Item>
                        <InputCustom
                            className="input-bus"
                            inputtype={InputTypes.Text} 
                            value={nombreSearch}
                            onChange={setNombreSearch}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    getFiltrarEmpleos();
                                }
                            }}
                            placeholder="Buscar vacante"
                            style={{height: 35, marginTop: 15, marginLeft: 10 }}
                            prefix={<SearchOutlined  />}
                            key={1} 
                        />
                    </Form.Item>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Form.Item
                        style={{ alignItems: 'center'}}
                    >
                        <Button type="primary" style={{ backgroundColor: '#4D49BE', marginTop: 15, height: 35, textAlign: 'center'}} onClick={getFiltrarEmpleos}>Buscar</Button>
                
                    </Form.Item>
                </Col>
            </Row>
        </Form>
       
        
        
    )
}

Search.propTypes = {
    nombreSearch: PropTypes.string.isRequired,
    setNombreSearch: PropTypes.func,
    getFiltrarEmpleos: PropTypes.func
};

export default Search;
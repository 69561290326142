import { Button, Card } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { useNavigate, useLocation } from "react-router-dom";
import {  useEffect, useRef } from "react";

const VacanteId = () => {

    const navigate = useNavigate();
    const paginationRef = useRef(null);
    const location = useLocation();

    const dataVacante = location.state?.record?.dataVacante ?? [];
    useEffect(() => {
        paginationRef.current.scrollIntoView({ behavior: 'smooth' });

    }, []);

    const handleBack = () => {
        navigate("/");
    }

    const handleAvanzar = (id, nombre) => {

        navigate("/datos-personales", {
            state: { record: { dataVacante: dataVacante } },
        });
    }

    const cardHeadStyle = {
        backgroundColor: '#04142b', //5c697c 04142b 051D49
        color: '#ffffff', // color blanco
        position: 'sticky',
        top: 0,
        zIndex: 2
    }


    const obtenerDias = (dias) => {
        let result = 'Hoy';

        if (dias > 1) {
            result = `Hace ${dias} Dias`;
        } else if (dias === 1) {
            result = `Hace ${dias} Dia`;
        }
        return result;

    }

    return (
        <div style={{ display: 'flex' }} ref={paginationRef}>
            <div

                className="vacantes"
                style={{
                    flex: 5,
                    position: 'sticky',
                    top: 80,
                    marginTop: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    marginBottom: 10
                }}
            >

                <div key={dataVacante?.key}>

                    <Card
                        title={<strong>{dataVacante?.nombreVacante}</strong>}
                        headStyle={cardHeadStyle}
                        extra={
                            <Button
                                type="primary"
                                style={{ height: 37, backgroundColor: '#00BD70' }}
                                onClick={() => handleAvanzar(dataVacante?.key, dataVacante?.nombreVacante)}
                            >
                                POSTULARSE
                            </Button>
                        }
                    >
                        <p> <strong>Descripción del puesto: <br /> </strong> {dataVacante?.descripcion} </p>
                        <br />
                        <div className="detallles">

                            <div className="detPart1">

                                <p> {/*<EnvironmentOutlined />*/} <strong> Lugar de trabajo:</strong> {dataVacante?.ubicacion}</p>
                                <p> {/*<ClockCircleOutlined />*/} <strong>Fecha publicación:</strong> {dataVacante?.fechaPublicacion}</p>
                                <p> {/*<HomeOutlined />*/} <strong> Experiencia:</strong> {dataVacante?.experiencia}</p>

                            </div>
                            <div className="detPart2">
                                <p> {/*<HomeOutlined />*/} <strong> Esquema:</strong> {dataVacante?.esquema.label}</p>
                                <p> {/*<FieldTimeOutlined />*/} <strong> Jornada:</strong> {dataVacante?.jornada.label}</p>
                                <p> {/*<HistoryOutlined />*/} <strong> Horario:</strong> {dataVacante?.horario.label}</p>

                            </div>
                            <div className="detPart3">
                                <strong>Requisitos</strong>
                                <ul style={{ marginLeft: -20 }}>
                                    {dataVacante?.requisitos?.map((requisito, index) => (
                                        <li key={index}>{requisito}</li>
                                    ))}

                                </ul>

                            </div>
                            <div className="detPart4">
                                <strong>Ofrecemos</strong>
                                <ul style={{ marginLeft: -20 }}>
                                    {Array.isArray(dataVacante.ofrecemos) ? (
                                        dataVacante.ofrecemos.map((beneficio, index) => (
                                            <li key={index}>{beneficio.label}</li>
                                        ))
                                    ) : (
                                        <li>No hay beneficios disponibles.</li> // Mensaje alternativo si no hay beneficios
                                    )}

                                </ul>
                            </div>
                        </div>

                        <br />

                        <div>
                            <Button
                                className="btnRegresar"
                                type="primary"
                                style={{ backgroundColor: '#00BD70' }}
                                icon={<ArrowLeftOutlined />}
                                onClick={() => handleBack()}
                            >
                                Regresar
                            </Button>
                            <p style={{ fontSize: '15px', marginTop: -30, textAlign: 'right' }} className="fechaPublicacion">Publicado hace<strong> {obtenerDias(dataVacante?.dias)} </strong> </p>
                        </div>










                    </Card>

                </div>


            </div>
        </div>

    )

}

export default VacanteId;
import React, { useEffect } from "react";
import { Button, Layout, theme } from "antd";
import Logo from "./Logo_tiny";
import MenuCustom from "./MenuCustom";

import logoVacantes from "../asset/images/VACANTES_DISPONIBLES.svg";

const { Header } = Layout;

export default function HeaderCustom() {

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
  
  }, []);

  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
        //position: "fixed",
        width: "100%",
        zIndex: 999,
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "right",
            paddingRight: 0,
            backgroundColor: "#04142b",    //051D49 - 04142b - 282c34
            height: 70,
        
          }}
        >
          <div style={{ width: "20%" }}>
            <Logo />
          </div>
          <div style={{ width: "60%", textAlign: 'center' }}>
            <img className="divOportunidades" src={logoVacantes} alt="INTRANET CORPORATIVA" height="60" style={{ marginTop: 0 }}/>
            {/* <h2 className="divOportunidades" style={{color: '#fff', fontSize: 26, marginTop: 1}}> VACANTES DISPONIBLES.</h2> */}
          </div>
          <div style={{ width: "20%", textAlign: 'right'}}>
            {/*<Button type="primary" style={{backgroundColor: '#00BD70'}}>Inicio</Button>*/}
            <MenuCustom/>
            
          </div>
        </div>
      </div>
    </Header>
  );
}
